import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div className="404">
      <div className="container">
        <div className="row">
          <div className="twelve columns">
            <p>Put the content here!</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
